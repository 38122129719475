@import '~@andes/technical-error/index';
@import '~@andes/badge/index';
@import '~@andes/button/base';
@import '~@andes/button/lib/styles/modifier/transparent';
@import '~@andes/snackbar/index';
@import '~@andes/thumbnail/base';
@import '~@andes/thumbnail/lib/styles/modifier/circle';
@import '~@andes/typography/index';
@import '~@andes/common/variables';

.error-boundary__container {
  height: 70vh;
}
