@import '~@webview/react-skeletor/index';

.skeleton-card {
  &_item {
    &:first-of-type {
      height: 14px;
      margin: 115px 24px 12px;
      width: 93px;
    }

    &:last-of-type {
      height: 8px;
      margin: 0 24px 40px;
      width: 66px;
    }
  }
}
