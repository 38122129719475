$andes-theme: 'mercadolibre';
@import '~@andes/common/index';

@import '../../components/Skeletons/hub/styles';
@import '../../components/ErrorBoundary/styles.scss';

@import '~@splinter/about/index';
@import '~@splinter/aboutcarousel/index';
@import '~@splinter/bannermplay/index';
@import '~@splinter/bannerplacement/index';
@import '~@splinter/bannervideo/index';
@import '~@splinter/benefits/index';
@import '~@splinter/bigheader/index';
@import '~@splinter/blogcarousel/index';
@import '~@splinter/button/index';
@import '~@splinter/cardposters/index';
@import '~@splinter/carouseldynamic/index';
@import '~@splinter/carousel/index';
@import '~@splinter/carouselhome/index';
@import '~@splinter/categorylist/index';
@import '~@splinter/categoryhub/index';
@import '~@splinter/categorytrendcontainer/index';
@import '~@splinter/clockdown/index';
@import '~@splinter/cobranded/index';
@import '~@splinter/cobrandedanchor/index';
@import '~@splinter/congrats/index';
@import '~@splinter/custombenefit/index';
@import '~@splinter/custombenefitanchor/index';
@import '~@splinter/disclaimer/index';
@import '~@splinter/dataviewer/index';
@import '~@splinter/faq/index';
@import '~@splinter/form/index';
@import '~@splinter/header/index';
@import '~@splinter/headeranchor/index';
@import '~@splinter/imagegallery/index';
@import '~@splinter/grid/index';
@import '~@splinter/mainslider/index';
@import '~@splinter/richtext/index';
@import '~@splinter/slides/index';
@import '~@splinter/subtitle/index';
@import '~@splinter/title/index';
@import '~@splinter/video/index';
@import '~@splinter/buttonanchor/index';
@import '~@splinter/headeranchor/index';
@import '~@splinter/bigheader/index';
@import '~@splinter/animatedsteps/index';
@import '~@splinter/hero/index';
@import '~@splinter/imagecarousel/index';
@import '~@splinter/tycmodal/index';
@import '~@splinter/visfacetedsearch/index';
@import '~@splinter/redirectmodal/index';
@import '~@splinter/sponsorbanner/index';
@import '~@splinter/coupon/index';
@import '~@splinter/contentdates/index';
@import '~@splinter/gamificationbanner/index';

body {
  background-color: #e7e7e7;
  font-family: 'Proxima Nova', -apple-system, 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  margin: 0;
}

main {
  overflow-x: hidden;
}

.hub a {
  text-decoration: none;
}

/* Light theme */
body.light {
  background-color: #fff;
}

/* search theme */
.search.hub {
  background-color: #f5f5f5;
}

.light.hub {
  background-color: #fff;
}

/* Dark Theme for Black Friday */
body.dark {
  background-color: #000;
}

.dark.hub {
  background-color: #000;

  // TODO: this rule its only for dark theme and its a workarround in order to decrease space beetween header and title
  .hub_section__hub_header {
    margin-bottom: 0;
  }

  h3,
  .item__banner.banner__numeric .description .title,
  .about .title {
    color: #333;
  }

  h1,
  .hub_subtitle,
  .title,
  .subtitle,
  .disclaimer,
  .donate-title,
  .donate_wrapper,
  .text__title p {
    color: #bbb;
  }

  .text__title {
    color: #bbb;
  }

  .dynamic__carousel-title {
    color: #bbb;
  }

  .hub__carousel .carousel__ul .carousel__item-img,
  .hub__carousel .carousel__brands-ul .carousel__item-img {
    padding: 0 !important;
  }

  .hub_carousel {
    .carousel-container {
      .prev-button::before,
      .prev-button::after,
      .next-button::before,
      .next-button::after {
        background-color: #bbb;
      }
    }
  }

  .hub__subtitle .text__primary {
    color: #bbb;
  }

  .andes-button--loud,
  .andes-button--loud:link,
  .andes-button--loud:visited,
  .andes-button--loud:focus {
    background-color: #fff159;
    border-color: #fff159;
    color: #000;
  }

  .container-faq,
  .container-title {
    background-color: #000;
  }
}
